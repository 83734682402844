import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import {useLanguage} from '@/hooks/LanguageContext';
import Head from 'next/head';
import {fileDomain} from '@/config/constants';

const NotFound = () => {
  const {translate} = useLanguage();

  return (
    <>
      <Head>
        <title>404</title>
      </Head>
      <div className="flex flex-col items-center justify-center lg:pt-[72px] pt-[75px] pb-[164px]">
        <div className="lg:w-[527.38px] lg:h-[280px] w-[294px] h-[156px]">
          <Image
            src={`${fileDomain}/public/boyner-corporate/404.png`}
            width={0}
            height={0}
            sizes="100vw"
            style={{width: '100%', height: 'auto'}}
            alt="404"
          />
        </div>
        <p className="lg:text-[20px] text-[16px] lg:leading-7 leading-[22px] lg:tracking-[0.4px] tracking-[0.32px] text-primary lg:pt-[72px] pt-[52px] lg:max-w-[740px] max-w-[343px] text-center font-normal">
          {translate('NotFoundContent')}
        </p>
        <Link legacyBehavior href="/">
          <a className="uppercase mt-8 lg:p-[17px] p-[14px] border border-solid group-hover:text-white hover:ease-in-out hover:duration-300 text-primary border-primary hover:text-white hover:bg-primary items-center flex">
            {translate('GoToHomepage')}
            <i className="icon right-arrow text-[20px] lg:text-[22px] lg:ml-4 ml-3"></i>
          </a>
        </Link>
      </div>
    </>
  );
};

export default NotFound;
